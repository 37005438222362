import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Flex, Box } from 'theme-ui';
import ContentText from '@solid-ui-components/ContentText';

const styles = {
  flex: {
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: ['column', 'column', 'column', 'row'],
  },
  box: {
    flex: 1,
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
};
function DividedScreen({ content }) {
  return (
    <Flex sx={styles.flex}>
      <Box sx={styles.box}>
        <ContentText
          content={content.title}
          variant='h3'
          sx={{ color: '#8568d6' }}
        />
        <Box>
          {content.description.textGroup.map((text) => (
            <ContentText content={{ text: text }} variant='p' />
          ))}
        </Box>
      </Box>
      <Box sx={styles.box}>
        <GatsbyImage
          image={getImage({
            ...content.avatar.src.ImageSharp_large_rectangular,
            __typename: 'ImageSharp_large_rectangular',
          })}
          loading='lazy'
          objectFit='cover'
          style={styles.image}
          alt={content.avatar.alt}
        />
      </Box>
    </Flex>
  );
}

export default DividedScreen;
