import { Box } from 'theme-ui';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { css } from '@emotion/react';
const styles = {
  wrapper: {
    backgroundColor: 'rgb(151, 103, 227)',
    color: 'white',
    py: 5,
    display: 'flex',
    justifyContent: 'center',
  },
  accordion: {
    maxWidth: 1050,
    width: '90%',
    '.accordion__item': {
      margin: '1rem 0',
    },
    '.accordion__button': {
      backgroundColor: '#8a58d9',
      padding: '.8rem .8rem .8rem 2rem',
      fontWeight: 'bold',
      cursor: `pointer`,
      position: `relative`,
      outline: `none`,
      transition: `all 0.15s ease-in`,
    },
    '.accordion__button:hover': {
      opacity: 0.75,
      '&::before': {
        borderColor: `beta`,
      },
    },
    '.accordion__button::before': {
      content: `' '`,
      width: `7px`,
      height: `7px`,
      marginRight: `12px`,
      borderBottom: `3px solid currentColor`,
      borderLeft: `3px solid currentColor`,
      position: `absolute`,
      right: 1,
      top: `50%`,
      transform: `translate(0, -50%) rotate(-45deg)`,
      transition: `all 0.15s ease-in`,
    },
    '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
      transform: `translate(0, -50%) rotate(135deg)`,
      transition: `transform 0.35s ease-in`,
    },
    '[hidden]': {
      display: 'none',
    },
    '.accordion__panel': {
      animation: `fadeIn 1s ease-out`,
      overflow: 'hidden',
      padding: '1rem .8rem 1rem 2rem',
    },
    '@keyframes fadeIn': {
      '0%': {
        maxHeight: 0,
        pb: 0,
      },
      '100%': {
        maxHeight: '100vh',
        pb: 0,
      },
    },
  },
};
function Questions({ content }) {
  return (
    <Box sx={styles.wrapper}>
      <Accordion allowZeroExpanded={true} css={css(styles.accordion)}>
        {content?.collection?.map((item) => (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{item?.title?.text}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {item?.description?.text ||
                item?.description?.textGroup.map((text) => <p>{text}</p>)}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

export default Questions;
