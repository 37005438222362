import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import Testimonials from '../@elegantstack/solid-ui-blocks/Testimonials/Block3';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import { Box, Container, merge } from 'theme-ui';
import React, { useRef } from 'react';
import { useHasBeenVisible } from '../../hooks/useVisibility';
import Hero from '../../content/components/DynamicLanding/Hero';
import DescriptionContent from '../../content/components/DynamicLanding/DescriptionContent';
import Publications from '../components/old-landing/Publications';
import FullWidthPlaceHolder from '../../content/components/shared/FullWidthPlaceHolder';
import HoveredImage from '../../content/components/shared/HoveredImage';
import theme from '../pages/new-landing/_theme';
import { graphql } from 'gatsby';
import HeroDescription from '../../content/components/DynamicLanding/HeroDescription';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Questions from '../../content/components/Referral/Questions';
import Video from '../components/old-landing/Video';
import LinedText from '../../content/components/Referral/LinedText';
import ContentButtons from '@solid-ui-components/ContentButtons';
import DividedScreen from '../../content/components/Referral/DividedScreen';

const styles = {
  pageStyle: {
    textAlign: 'center',
  },
  heroScreen: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  reviewWrapper: {
    backgroundColor: 'rgba(231, 217, 217, 0.39)',

    h3: {},
  },
};

const Referral = (props) => {
  const { allBlockContent } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const testimonialsRef = useRef();
  const bottomRef = useRef();
  const halfPageVideo = useRef();
  const hasScrolledReason = useHasBeenVisible(testimonialsRef);
  const hasScrolledBottom = useHasBeenVisible(bottomRef);
  const hasScrolledVideo = useHasBeenVisible(halfPageVideo);
  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title={'Referral'} />
      <Box sx={{}}>
        <Header content={content['header']} />
        <Box sx={{ position: 'relative' }}>
          <GatsbyImage
            image={getImage(content['referral'].images[0]?.src?.ImageSharp)}
            alt={content['referral'].images[0].alt}
            objectFit='cover'
            style={{
              position: 'absolute',
              top: 0,
              zIndex: -3,
              width: '100%',
              height: '100%',
            }}
          />
          <Container>
            <Hero
              staticContent={{
                heroImage: content['referral-heroImage'],
              }}
              dynamicContent={{}}
            />
          </Container>
        </Box>
      </Box>
      <HeroDescription
        dynamicContent={{}}
        staticContent={content['referral-heroDescription']}
      />
      <DescriptionContent
        staticContent={{}}
        dynamicContent={{}}
        button={{ ...content['referral-heroImage'].buttons[0], type: 'block' }}
      />
      <Divider space='2' />
      {content['old-publications'] && (
        <Publications
          style={{ backgroundColor: 'white' }}
          content={content['old-publications']}
        />
      )}
      <Divider space='2' />
      <DividedScreen content={content['referral-howToUseCoupon']} />
      <Questions content={content['referral-questions']} />
      <Container>
        {content['referral-video'] &&
          (hasScrolledVideo ? (
            <Video
              customStyle={{
                maxWidth: 900,
                width: '100%',
                height: '570px',
                iframe: { width: '100%', height: '100%' },
              }}
            />
          ) : (
            <FullWidthPlaceHolder reference={halfPageVideo} />
          ))}
        <LinedText text={content['referral-video'].kicker} variant='h3' />
      </Container>
      {hasScrolledReason ? (
        <Box sx={styles.reviewWrapper} px='2' pb='3' pt='5'>
          <Testimonials content={content['referral-testimonials']} />
        </Box>
      ) : (
        <FullWidthPlaceHolder
          reference={testimonialsRef}
          customStyle={{ height: '1030px', backgroundColor: '#F3EDED' }}
        />
      )}
      {hasScrolledBottom ? (
        <HoveredImage
          header1={content['referral-bottomDescription'].title.text}
          image={content['referral'].images[1]}
          style={{ textAlign: 'center' }}
          button={{
            ...content['referral-heroImage'].buttons[0],
            type: 'block',
          }}
          customComponent={
            <ContentButtons
              content={content['referral-bottomDescription'].buttons}
              variant='vertical'
              wrapperStyles={{
                flexDirection: 'row',
                margin: '0 auto',
                justifyContent: 'center',
                a: {
                  padding: '0 .5rem',
                },
              }}
            />
          }
        />
      ) : (
        <FullWidthPlaceHolder
          reference={bottomRef}
          customStyle={{ height: '500px', backgroundColor: '#3C261C' }}
        />
      )}
      <Divider space='5' />
      <Footer content={content?.['footer']} />
    </Layout>
  );
};
export const query = graphql`
  query siteReferralBlockContent($blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: [
            "footer"
            "header"
            "referral"
            "referral-heroImage"
            "referral-heroDescription"
            "referral-heroImage"
            "old-publications"
            "referral-bottomDescription"
            "referral-testimonials"
            "referral-video"
            "referral-howToUseCoupon"
            "referral-questions"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default Referral;
