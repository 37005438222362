import ContentText from '@solid-ui-components/ContentText';
const styles = {
  childrenWrapper: {
    display: 'flex',
    textAlign: 'center',
    transform: 'translateY(-50%)',
    marginBottom: 0,
    textTransform: 'uppercase',
    width: 'auto',
    zIndex: 1,
    padding: '0 2rem',
    backgroundColor: 'white',
    alignItems: 'center',
    ':before, :after': {
      content: "' '",
      flex: 1,
      height: '2px',
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    ':before': {
      marginRight: '1rem',
    },
    ':after': {
      marginLeft: '1rem',
    },
  },
};
function LinedText({ text, variant }) {
  return (
    <ContentText content={text} variant={variant} sx={styles.childrenWrapper} />
  );
}

export default LinedText;
